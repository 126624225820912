.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.purple-bold-title {
  color: #4B0082;  
  font-weight: bold;
}

.purple-title {
  color: #4B0082;  
}

.button-hover:hover img {
  filter: brightness(0) invert(1); /* This will make the image white */
}

/* App.css */
.bullet-point::before {
  content: '•'; /* Bullet point character */
  color: #6A0DAD; /* Color of the bullet point */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em; /* Increase the font size */
  line-height: 1; /* Adjust the line height */
}

.bg-transparent {
  background-color: rgba(255, 255, 255, 0.6); /* %60 şeffaf beyaz */
}

.bg-white {
  background-color: white;
}

.transition-colors {
  transition: background-color 0.3s ease;
}

/* Add this to your App.css file */
.text-white {
  color: white;
}

.text-purple {
  color: rgb(100, 19, 151);
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire section */
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.3) 100%);
  filter: blur(15px); /* Reduced blur amount */
  z-index: 1;
  pointer-events: none; /* Ensure the overlay does not interfere with interactions */
}

.hero-section .container {
  position: relative;
  z-index: 2; /* Ensure the content is above the blur overlay */
}

.checkmark::before {
  content: "✔";
  color: #DD6B20; /* Tailwind'deki 'text-orange-800' renginin hex değeri */
  margin-right: 0.5rem;
}

.bold-grey-text {
  color: grey;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  display: inline-block;
}

.bold-darkgrey-text {
  color: darkgrey;
  font-weight: bold;
}

.text-grey {
  color: rgb(200, 200, 200);
  margin-bottom: 20px;
  margin-top: 10px;
}

.summary-text {  
  margin-bottom: 10px;
  margin-top: 20px;
}

.pic-margin {    
  margin-top: 20px;
}
